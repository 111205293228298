import i18n from '../i18n';
import AdminService from '../services/AdminService';

const t = i18n.t.bind(i18n);

/**
 * Moves a candidate to the previous stage and updates the job application status.
 * @async
 * @param {string} status - The current status of the candidate.
 * @param {string} subStatus - The current sub-status of the candidate.
 * @param {Object} candidate - The candidate object to move to the previous stage.
 * @param {Function} refetch - The function to refetch the data after the candidate has been moved.
 * @param {Function} setIsFetching - The function to set the fetching state of the component.
 * @returns {Promise<void>} A Promise that resolves when the candidate has been moved to the previous stage.
 */

export const moveCandidateToPreviousStage = async (
  status,
  subStatus,
  candidate,
  refetch,
  setIsFetching
) => {
  try {
    if (setIsFetching) {
      setIsFetching(true);
    }
    await AdminService.changeMyJobApplicationStatus({
      jobApplicationId: candidate.id,
      status,
      subStatus,
    });
    await refetch();
  } catch (error) {
    console.log(error);
  } finally {
    if (setIsFetching) {
      setIsFetching(false);
    }
  }
};

/**
 * Rejects a candidate and updates the job application status.
 * @async
 * @param {Object} candidate - The candidate object to reject.
 * @param {Function} refetch - The function to refetch the data after the candidate has been rejected.
 * @param {Function} showNotification - The function to show a notification after the candidate has been rejected.
 * @param {Function} setIsFetching - The function to set the fetching state of the component.
 * @returns {Promise<void>} A Promise that resolves when the candidate has been rejected.
 */

export const rejectCandidate = async (
  candidate,
  refetch,
  showNotification,
  noShow,
  setIsFetching
) => {
  try {
    setIsFetching(true);

    const prevSubStatus = candidate.subStatus;
    await AdminService.changeMyJobApplicationStatus({
      jobApplicationId: candidate.id,
      status: candidate.status,
      subStatus: noShow ? 'NoShow' : 'Rejected',
    });

    // If the candidate no shows for onboarding, we need to update the onboarding status to trigger a email notification
    if (
      noShow &&
      candidate.jobApplicationOnboardings.length &&
      !candidate.jobApplicationJoinings.length
    ) {
      const onboarding = candidate.jobApplicationOnboardings?.at(-1);

      if (onboarding) {
        await AdminService.changeJobApplicationOnboarding({
          ...onboarding,
          onboardingId: onboarding.id,
          jobApplicationId: candidate.id,
          status: 'NoShow',
        });
      }
    }

    await refetch();
    showNotification({
      text: t('helpers.candidates.rejectCandidate'),
      undo: () =>
        moveCandidateToPreviousStage(
          candidate.status,
          prevSubStatus,
          candidate,
          refetch,
          setIsFetching
        ),
    });
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

/**
 * Moves a candidate to the Offer stage and updates the job application status.
 * @async
 * @param {Object} candidate - The candidate object to move to the Offer stage.
 * @param {Function} refetch - The function to refetch the data after the candidate has been moved.
 * @param {Function} showNotification - The function to show a notification after the candidate has been moved.
 * @param {Function} setIsFetching - The function to set the fetching state of the component.
 * @param {string} additionalInfo - Reason of moving the candidate to the Offer stage.
 * @returns {Promise<void>} A Promise that resolves when the candidate has been moved to the Offer stage.
 */

export const moveCandidateToOffer = async (
  candidate,
  refetch,
  showNotification,
  setIsFetching,
  additionalInfo
) => {
  try {
    setIsFetching(true);

    const prevSubStatus = candidate.subStatus;
    await AdminService.changeMyJobApplicationStatus({
      jobApplicationId: candidate.id,
      status: 'Offer',
      subStatus: 'Pending',
      additionalInfo,
    });
    await refetch();
    showNotification({
      text: t('helpers.candidates.moveToOffer'),
      undo: () =>
        moveCandidateToPreviousStage(
          candidate.status,
          prevSubStatus,
          candidate,
          refetch,
          setIsFetching
        ),
    });
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

/**
 * Moves a candidate to the Onboard stage and updates the job application status.
 * @async
 * @param {Object} candidate - The candidate object to move to the Onboard stage.
 * @param {Function} refetch - The function to refetch the data after the candidate has been moved.
 * @param {Function} showNotification - The function to show a notification after the candidate has been moved.
 * @param {Function} setIsFetching - The function to set the fetching state of the component.
 * @param {string} additionalInfo - Reason of moving the candidate to the Onboard stage.
 * @returns {Promise<void>} A Promise that resolves when the candidate has been moved to the Onboard stage.
 */

export const moveCandidateToOnboard = async (
  candidate,
  refetch,
  showNotification,
  setIsFetching,
  additionalInfo
) => {
  try {
    setIsFetching(true);

    const prevSubStatus = candidate.subStatus;
    await AdminService.changeMyJobApplicationStatus({
      jobApplicationId: candidate.id,
      status: 'Onboard',
      subStatus: 'Pending',
      additionalInfo,
    });
    await refetch();
    showNotification({
      text: t('helpers.candidates.moveToOnboard'),
      undo: () =>
        moveCandidateToPreviousStage(
          candidate.status,
          prevSubStatus,
          candidate,
          refetch,
          setIsFetching
        ),
    });
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

/**
 * Moves a candidate to the Interview stage and updates the job application status.
 * @async
 * @param {Object} candidate - The candidate object to move to the Interview stage.
 * @param {Function} refetch - The function to refetch the data after the candidate has been moved.
 * @param {Function} showNotification - The function to show a notification after the candidate has been moved.
 * @param {Function} setIsFetching - The function to set the fetching state of the component.
 * @param {string} additionalInfo - Reason of moving the candidate to the Interview stage.
 * @returns {Promise<void>} A Promise that resolves when the candidate has been moved to the Interview stage.
 */

export const moveCandidateToInterview = async (
  candidate,
  refetch,
  showNotification,
  setIsFetching,
  additionalInfo
) => {
  try {
    setIsFetching(true);

    const prevSubStatus = candidate.subStatus;
    await AdminService.changeMyJobApplicationStatus({
      jobApplicationId: candidate.id,
      status: 'Interview',
      subStatus: 'Pending',
      additionalInfo,
    });
    refetch();
    showNotification({
      text: t('helpers.candidates.moveToInterview'),
      undo: () =>
        moveCandidateToPreviousStage(
          candidate.status,
          prevSubStatus,
          candidate,
          refetch,
          setIsFetching
        ),
    });
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

/**
 * Retakes an assessment for a candidate.
 * @async
 * @param {Object} candidate - The candidate object to retake the assessment for.
 * @param {Function} refetch - The function to refetch the data after the assessment has been retaken.
 * @param {Function} showNotification - The function to show a notification after the assessment has been retaken.
 * @param {Function} setIsFetching - The function to set the fetching state of the component.
 * @returns {Promise<void>} A Promise that resolves when the assessment has been retaken.
 */

export const retakeAssessment = async (
  candidate,
  refetch,
  showNotification,
  setIsFetching
) => {
  try {
    setIsFetching(true);

    await AdminService.changeMyJobApplicationStatus({
      jobApplicationId: candidate.id,
      status: 'Screen',
      subStatus: 'Requested',
    });
    await refetch();
    showNotification({
      text: t('helpers.candidates.retakeAssessment'),
    });
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

/**
 * Sends a reminder email to a candidate.
 * @async
 * @param {Object} candidate - The candidate object to send the reminder email to.
 * @param {Function} showNotification - The function to show a notification after the reminder email has been sent.
 * @param {Function} setIsFetching - The function to set the fetching state of the component.
 * @returns {Promise<void>} A Promise that resolves when the reminder email has been sent.
 */

export const sendReminder = async (
  candidate,
  refetch,
  showNotification,
  setIsFetching
) => {
  try {
    setIsFetching(true);

    await AdminService.remindAssessment({
      id: candidate?.id,
    });
    await refetch();
    showNotification({
      text: t('helpers.candidates.sendReminder'),
    });
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

/**
 * Sends an offer reminder email to a candidate.
 * @async
 * @param {Object} candidate - The candidate object to send the reminder email to.
 * @param {Function} showNotification - The function to show a notification after the reminder email has been sent.
 * @param {Function} setIsFetching - The function to set the fetching state of the component.
 * @returns {Promise<void>} A Promise that resolves when the reminder email has been sent.
 */

export const sendOfferReminder = async (
  candidate,
  refetch,
  showNotification,
  setIsFetching
) => {
  try {
    setIsFetching(true);

    await AdminService.remindOffer(candidate?.id);
    await refetch();
    showNotification({
      text: t('helpers.candidates.sendOfferReminder'),
    });
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};

/**
 * Changes candidate joining status to NoShow
 * @async
 * @param {Object} candidate - The candidate object to change the joining status to NoShow.
 * @param {Function} refetch - The function to refetch the data after the candidate has been changed.
 * @param {Function} setIsFetching - The function to set the fetching state of the component.
 * @returns {Promise<void>} A Promise that resolves when the candidate has been changed.
 */

export const changeJoiningStatusToNoShow = async (
  candidate,
  refetch,
  setIsFetching
) => {
  try {
    setIsFetching(true);

    const joining = candidate.jobApplicationJoinings?.[0];

    if (!joining) {
      return;
    }

    await AdminService.changeJobApplicationStartDate({
      ...joining,
      status: 'NoShow',
      joiningId: joining.id,
      jobApplicationId: candidate.id,
    });
    await refetch();
  } catch (error) {
    console.log(error);
  } finally {
    setIsFetching(false);
  }
};
