/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
import moment from 'moment';

import AdminService from '../../../../../services/AdminService';

export const externalSources = [
  {
    label: 'LinkedIn',
    value: 'LinkedIn',
  },
  {
    label: 'Kariyer Net',
    value: 'Kariyer Net',
  },
  {
    label: 'Eleman Net',
    value: 'Eleman Net',
  },
  {
    label: 'Secret CV',
    value: 'Secret CV',
  },
  {
    label: 'Employee Referral',
    value: 'Employee Referral',
  },
  {
    label: 'Other Sources',
    value: 'Other Sources',
  },
];

export const nameInputs = [
  {
    type: 'text',
    label: 'First Name',
    placeholder: 'First Name',
    name: 'firstName',
    validation: Yup.string()
      .required('First Name cannot be empty.')
      .matches(/^[A-Za-z^]*$/, 'Special characters not allowed.'),
  },
  {
    type: 'text',
    label: 'Last Name',
    placeholder: 'Last Name',
    name: 'lastName',
    validation: Yup.string()
      .required('Last Name cannot be empty.')
      .matches(/^[A-Za-z^]*$/, 'Special characters not allowed.'),
  },
];

export const detailsMainInputs = [
  {
    type: 'text',
    label: 'Email',
    placeholder: 'Enter Email Address',
    name: 'email',
    validation: Yup.string()
      .email('Invalid email')
      .required('Email cannot be empty.'),
  },
  {
    type: 'phone',
    label: 'Phone',
    placeholder: 'Enter Phone Number',
    name: 'phone',
    validation: Yup.string()
      .test('is-valid-phone', 'Phone number is not valid', async (value) => {
        if (!value) {
          return true; // Skip validation if the value is empty
        }

        const { isValidPhoneNumber, AsYouType } = await import(
          'libphonenumber-js'
        );

        const formattedNumber = new AsYouType().input(value);

        const formattedNumberWithPlus = formattedNumber.startsWith('+')
          ? formattedNumber
          : `+${formattedNumber}`;

        return isValidPhoneNumber(
          new AsYouType().input(formattedNumberWithPlus)
        );
      })
      .required('Phone cannot be empty.'),
  },
  {
    type: 'date',
    label: 'Date of birth',
    placeholder: 'Enter Date of Birth',
    name: 'birthDate',
    validation: Yup.date()
      .required('Date of Birth cannot be empty.')
      .max(new Date(), 'Date of Birth cannot be in the future.'),
    calendarDefaultDate: new Date(
      new Date().setFullYear(new Date().getFullYear() - 16)
    )
      .toISOString()
      .slice(0, 10), // 16 years ago
  },
  {
    type: 'text',
    label: 'Location',
    placeholder: 'Enter City & Country',
    name: 'city',
    validation: Yup.string().required('Location cannot be empty.'),
  },
  {
    type: 'select',
    label: 'Education',
    placeholder: 'Select Education',
    name: 'userProfileGraduation',
    fetchOptions: {
      dataName: 'educationOptionsBasicInfo',
      getDataHandler: AdminService.getEducations,
    },
    validation: Yup.object().required('Education cannot be empty.'),
  },
  {
    type: 'select',
    label: 'Candidate Source',
    placeholder: 'Select Source',
    name: 'source',
    validation: Yup.object().required('Candidate source cannot be empty.'),
    options: [
      {
        label: 'Walk-In',
        value: 'Walk-In',
      },
      {
        label: 'Internal Referral',
        value: 'Internal Referral',
      },
      {
        label: 'Direct Email',
        value: 'Direct Email',
      },
      {
        label: 'Other Source',
        value: 'Other Source',
      },
    ],
  },
  {
    type: 'select',
    label: 'Experience',
    placeholder: 'Select Education',
    name: 'hasExperience',
    options: [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ],
    validation: Yup.object().required('Experience cannot be empty.'),
  },
  {
    type: 'select',
    label: 'placeholder',
    placeholder: '',
    name: '',
    options: [],
    validation: Yup.string(),
    isHidden: () => true,
  },
  {
    type: 'select',
    label: 'External Source List',
    placeholder: 'Select External Source',
    name: 'externalSource',
    options: externalSources,
    validation: Yup.object(),
    shouldRender: (values) =>
      values.source?.value === 'Other Source' &&
      values.externalSource?.value !== 'Other Sources',
  },
  {
    type: 'text',
    label: 'External Source List',
    placeholder: 'Enter External Source',
    name: 'otherSourceName',
    validation: Yup.string().when(
      ['source', 'externalSource'],
      (source, externalSource, schema) => {
        // Check if source value is 'Other Source' and externalSource value is not 'Other Sources'

        if (
          source?.value === 'Other Source' &&
          externalSource?.value === 'Other Sources'
        ) {
          // console.log('IS IT WORKING?', source.value, externalSource.value);
          return schema
            .trim()
            .max(25, 'Maximum 25 characters allowed.')
            .required('This field is required');
        }
        return schema.notRequired();
      }
    ),
    shouldRender: (values) => {
      return (
        values.source?.value === 'Other Source' &&
        values.externalSource?.value === 'Other Sources'
      );
    },
  },
];

export const experienceInputs = [
  {
    type: 'text',
    label: 'Job Title',
    placeholder: 'Enter Job Title',
    name: 'jobTitle',
    validation: Yup.string().required('Job Title cannot be empty.'),
  },
  {
    type: 'text',
    label: 'Name of Company',
    placeholder: 'Enter Job Title',
    name: 'company',
    validation: Yup.string().required('Job Title cannot be empty.'),
  },
  {
    type: 'date',
    label: 'Start Date',
    placeholder: 'Enter Start Date',
    name: 'startDate',
    validation: Yup.date()
      .required('Start Date cannot be empty.')
      .test(
        'is-future-date',
        'Start Date cannot be in the future.',
        (value) => !moment(value).isAfter(moment())
      ),
  },
  {
    type: 'date',
    label: 'End Date',
    placeholder: 'Enter End Date',
    name: 'endDate',
    validation: Yup.date()
      .required('End Date cannot be empty.')
      .test(
        'is-future-date',
        'End Date cannot be in the future.',
        (value) => !moment(value).isAfter(moment())
      )
      .when('startDate', (startDate, schema) =>
        schema.test(
          'is-after-start-date',
          'Cannot be earlier than Start Date.',
          (endDate) =>
            !startDate ||
            !endDate ||
            moment(endDate).isSameOrAfter(moment(startDate))
        )
      ),
  },
];

export const validationSchema = Yup.object().shape(
  [...detailsMainInputs, ...nameInputs].reduce(
    (acc, input) => {
      acc[input.name] = input.validation;
      return acc;
    },
    {
      userProfileExperiences: Yup.array().of(
        Yup.object().shape(
          experienceInputs.reduce((acc, input) => {
            acc[input.name] = input.validation;
            return acc;
          }, {})
        )
      ),
      userProfileFileResume: Yup.mixed(),
      jobPostId: Yup.object(),
      applicationStatus: Yup.object().when('jobPostId', {
        is: (jobPostId) => {
          return jobPostId && jobPostId.value;
        }, // Check if `jobPostId` is not empty
        then: Yup.object().required('Stage cannot be empty.'),
        otherwise: Yup.object(), // No additional constraints if `jobPostId` is empty
      }),
      applicationSubStatus: Yup.string().nullable(),
    }
  )
);
