export function startTimeNotInPastIfToday(eventDateFieldName) {
  return (value, ctx) => {
    const eventDate = ctx.parent[eventDateFieldName];

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Convert both joiningDate and today to their date strings
    const joiningDateOnly = new Date(eventDate).toISOString().split('T')[0];
    const todayOnly = today.toISOString().split('T')[0];
    const isToday = joiningDateOnly === todayOnly;

    if (isToday) {
      const now = new Date();
      const inputTime = new Date(`${now.toISOString().split('T')[0]}T${value}`);
      return inputTime.getTime() >= now.getTime();
    }
    return true;
  };
}
