/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useMemo } from 'react';

import classNames from 'classnames';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import FormButtonsManager from '../../../FormButtonsManager';
import ContactInfo from '../ContactInfo';
import Header from '../Header';
import Details from '../Details';
import JoiningDetails from './JoiningDetails';
// import Button from '../../../../Button';
import classes from './styles.module.scss';
import UserService from '../../../../../services/UserService';
import TimeRange from '../../../../Form/TimeRange';
import FormDropdown from '../../../../Form/FormDropdown';
import MultiDropdown from '../../../../Form/MultiDropdown';
import SelectedUsers from '../../../SelectedUsers';
import Textarea from '../../../../Form/Textarea';
import DatePicker from '../../../../Form/DatePicker';

import yupSchemaToFields from '../../../../../helpers/yupSchemaToFields';
import AdminService from '../../../../../services/AdminService';
import { UiContext } from '../../../../../context/UiContext';
// import { getTime } from '../../../../../helpers/getEvents';
import {
  getTimezoneDate,
  getTimezoneTime,
  setTimezoneDate,
  setTimezoneTime,
} from '../../../../../helpers/timezones';
import { startTimeNotInPastIfToday } from '../../../../../helpers/validation';

/* function getEventDate(date) {
  const tempDate = new Date(date);

  return `${tempDate.getDate()} ${tempDate.toLocaleString('en-us', {
    month: 'long',
  })} ${tempDate.getFullYear()}
  `;
} */

export default function Joining({
  isEventDetailsVisible,
  handleClose,
  event,
  refetch,
  currentUser,
  setCurrentCandidate,
  setIsCandidateModalVisible,
}) {
  const [isOpened, setIsOpened] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isTriedToSubmit, setIsTriedToSubmit] = useState(false);

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { showUnknownErrorModal, showNotification, setIsFetching, showModal } =
    useContext(UiContext);

  const { data: me } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  useEffect(() => {
    if (isEventDetailsVisible) {
      setTimeout(() => {
        setIsOpened(true);
      }, 100);
    }
  }, [isEventDetailsVisible]);

  const closePopup = () => {
    setIsOpened(false);
    setTimeout(() => {
      handleClose();
    }, 300);
  };

  const closeAndNotify = (message) => {
    handleClose();
    refetch();
    showNotification({
      text: message,
    });
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        joiningDate: yup.date().required(t('common.requiredField')),
        location: yup.object(),
        userIds: yup.array().required(t('common.requiredField')),
        note: yup.string().trim(),
        startTime: yup
          .string()
          .trim()
          .required(t('common.requiredField'))
          .test(
            'not-in-past-if-today',
            t('modals.ScheduleInterviewModal.startTimeGreaterThanCurrentTime'),
            startTimeNotInPastIfToday('joiningDate')
          ),
        endTime: yup
          .string()
          .trim()
          .required(t('common.endTimeRequired'))
          .test(
            'less-than-start',
            t('common.endTimeCannotBeLower'),
            (value, ctx) => {
              if (!ctx.parent.startTime) {
                return true;
              }
              const endTime = parseInt(value?.replace(':', ''), 10);
              const startTime = parseInt(
                ctx.parent.startTime?.replace(':', ''),
                10
              );
              return !(endTime < startTime);
            }
          ),
      }),
    [t]
  );

  const formik = useFormik({
    initialValues: {
      joiningDate: isEdit
        ? getTimezoneDate(
            event?.event?.joiningDate,
            me?.userProfile?.timezone
          )[0]
        : '',
      location: isEdit
        ? {
            value: event?.event?.location?.id,
            label: event?.event?.location?.name,
          }
        : '',
      userIds: isEdit
        ? event?.event?.users?.map((user) => ({
            label: user.userProfile?.fullName,
            value: user.id,
          }))
        : '',
      note: isEdit ? event?.event?.note : '',
      startTime: isEdit
        ? getTimezoneTime(event?.event?.startTime, me?.userProfile?.timezone)
        : '',
      endTime: isEdit
        ? getTimezoneTime(event?.event?.endTime, me?.userProfile?.timezone)
        : '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);

        /*    if (isEdit) {
          await AdminService.deleteJobApplicationStartDate({
            jobApplicationId: event?.jobApplicationId,
            joiningid: event?.id,
          });
        } */

        const requestHandler = isEdit
          ? AdminService.changeJobApplicationStartDate
          : AdminService.createJobApplicationStartDate;

        await requestHandler({
          joiningId: event?.id,
          joiningDate: setTimezoneDate(
            values.joiningDate,
            values.startTime,
            me?.userProfile?.timezone
          ),
          locationName: values.location.label,
          locationId: values.location.value,
          userIds: values.userIds.map((user) => user.value),
          note: values.note,
          startTime: setTimezoneTime(
            values.startTime,
            me?.userProfile?.timezone
          ),
          endTime: setTimezoneTime(values.endTime, me?.userProfile?.timezone),
          jobApplicationId: event?.jobApplicationId,
          status: isEdit ? 'Rescheduled' : 'Scheduled',
        });

        handleClose();
        await refetch();
        closeAndNotify(
          t(
            'dashboardComponents.Calendar.EventDetails.Joining.Start date updated successfully'
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    },
    enableReinitialize: true,
  });

  const declineEvent = async () => {
    setIsFetching(true);

    if (event.userId === currentUser.id) {
      const prevStatus = event?.event?.status;
      const undo = async () => {
        try {
          await AdminService.changeJobApplicationInterview({
            joiningId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            joiningDate: event?.event?.joiningDate,
            note: event?.event?.note,
            startTime: event?.event?.startTime,
            endTime: event?.event?.endTime,
            userIds: event?.event?.users.map((user) => user.id),
            location: event?.event?.location,
            status: prevStatus,
          });
          if (refetch) {
            await refetch();
          }
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
          setIsFetching(false);
        }
      };
      try {
        await AdminService.changeJobApplicationStartDate({
          joiningId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          joiningDate: event?.event?.joiningDate,
          note: event?.event?.note,
          startTime: event?.event?.startTime,
          endTime: event?.event?.endTime,
          userIds: event?.event?.users.map((user) => user.id),
          location: event?.event?.location,
          status: 'Declined',
        });
        if (refetch) {
          await refetch();
        }
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventDetails.Joining.Joining declined'
          ),
          undo,
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        closePopup();
        setIsFetching(false);
      }
    } else {
      const currentInvite = event?.event?.jobApplicationJoiningInvites.find(
        (invite) => invite?.userId === currentUser?.id
      );
      const prevStatus = currentInvite?.status;
      const undo = async () => {
        try {
          await AdminService.changeJobApplicationStartDateInvite({
            joiningId: event?.id,
            jobApplicationId: event?.jobApplicationId,
            invitationid: currentInvite.id,
            note: currentInvite.note,
            status: prevStatus,
          });
          if (refetch) {
            await refetch();
          }
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
          setIsFetching(false);
        }
      };

      try {
        await AdminService.changeJobApplicationStartDateInvite({
          joiningId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          invitationid: currentInvite.id,
          note: currentInvite.note,
          status: 'Declined',
        });
        if (refetch) {
          await refetch();
        }
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventDetails.Joining.Joining invitation declined'
          ),
          undo,
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        closePopup();
        setIsFetching(false);
      }
    }
    setIsFetching(false);
  };

  const acceptEvent = async () => {
    setIsFetching(true);
    if (event.userId === currentUser.id) {
      try {
        await AdminService.changeJobApplicationStartDate({
          joiningId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          joiningDate: event?.event?.joiningDate,
          note: event?.event?.note,
          startTime: event?.event?.startTime,
          endTime: event?.event?.endTime,
          userIds: event?.event?.users.map((user) => user.id),
          location: event?.event?.location,
          status: 'Accepted',
        });
        if (refetch) {
          await refetch();
        }
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventDetails.Joining.Joining accepted'
          ),
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        closePopup();
        setIsFetching(false);
      }
    } else {
      try {
        const currentInvite = event?.event?.jobApplicationJoiningInvites.find(
          (invite) => invite?.userId === currentUser?.id
        );
        await AdminService.changeJobApplicationStartDateInvite({
          joiningId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          invitationid: currentInvite.id,
          note: currentInvite.note,
          status: 'Accepted',
        });
        if (refetch) {
          await refetch();
        }
        showNotification({
          text: t(
            'dashboardComponents.Calendar.EventDetails.Joining.Joining invitation accepted'
          ),
        });
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        closePopup();
        setIsFetching(false);
      }
    }
    setIsFetching(false);
  };

  const cancelEvent = () => {
    const prevStatus = event?.event?.status;
    const undo = async () => {
      try {
        setIsFetching(true);

        await AdminService.changeJobApplicationStartDate({
          joiningId: event?.id,
          jobApplicationId: event?.jobApplicationId,
          joiningDate: event?.event?.joiningDate,
          note: event?.event?.note,
          startTime: event?.event?.startTime,
          endTime: event?.event?.endTime,
          userIds: event?.event?.users.map((user) => user.id),
          location: event?.event?.location,
          status: prevStatus,
        });

        await refetch();
      } catch (error) {
        console.log(error);
        showUnknownErrorModal();
      } finally {
        setIsFetching(false);
      }
    };
    showModal({
      icon: 'infoIcon',
      title: t(
        'dashboardComponents.Calendar.EventDetails.Joining.Cancel Joining'
      ),
      text: t(
        'dashboardComponents.Calendar.EventDetails.Joining.Are you sure you want to cancel?'
      ),
      onConfirm: async () => {
        try {
          setIsFetching(true);

          await AdminService.cancelStartDate({
            jobApplicationId: event?.jobApplicationId,
            joiningId: event?.id,
          });

          await refetch();
          showNotification({
            text: t(
              'dashboardComponents.Calendar.EventDetails.Joining.Joining canceled'
            ),
            undo,
          });
        } catch (error) {
          console.log(error);
          showUnknownErrorModal();
        } finally {
          setIsFetching(false);
        }
      },
      onCancel: () => {},
      dismissButtonLabel: 'No',
      confirmButtonLabel: 'Yes',
    });
  };

  const status = event?.event?.status;
  /* const isDisabled =
    Object.keys(formik.values).some(
      (key) => !formik.values[key] && key !== 'note'
    ) || !formik.dirty;
 */
  /* let buttons = (
    <>
      <Button variant="delete" width={250} height={52} onClick={declineEvent}>
        {t('common.decline')}
      </Button>
      <Button width={250} height={52} onClick={acceptEvent}>
        {t('common.accept')}
      </Button>
    </>
  );

  if (
    (currentUser?.id === event?.userId && status === 'Accepted') ||
    event?.invites?.find((invite) => invite.userId === currentUser?.id)
      ?.status === 'Accepted'
  ) {
    buttons = (
      <Button variant="delete" width={250} height={52} onClick={declineEvent}>
        {t('common.decline')}
      </Button>
    );
  }

  if (
    (currentUser?.id === event?.userId && status === 'Declined') ||
    event?.invites?.find((invite) => invite?.userId === currentUser?.id)
      ?.status === 'Declined'
  ) {
    buttons = (
      <Button width={250} height={52} onClick={acceptEvent}>
        {t('common.accept')}
      </Button>
    );
  }

  if (currentUser?.id === event?.event?.organizer.id) {
    buttons = (
      <>
        <Button variant="delete" width={250} height={52} onClick={cancelEvent}>
          {t('common.cancel')}
        </Button>
        <Button
          width={250}
          height={55}
          onClick={() => {
            setIsEdit(true);
          }}
        >
          {t('common.edit')}
        </Button>
      </>
    );
  }

  if (isEdit) {
    buttons = (
      <>
        <Button
          variant="secondary"
          width={250}
          height={55}
          onClick={() => setIsEdit(false)}
        >
          {t('common.reset')}
        </Button>
        <Button
          width={250}
          height={55}
          onClick={() => {
            setIsTriedToSubmit(true);
            formik.handleSubmit();
          }}
          disabled={isDisabled}
        >
          {t('common.save')}
        </Button>
      </>
    );
  } */

  let onLeftButtonClick = declineEvent;
  let onRightButtonClick = acceptEvent;
  let leftButtonLabel = t('common.decline');
  let rightButtonLabel = t('common.accept');

  if (currentUser?.id === event?.event?.organizer.id) {
    onLeftButtonClick = cancelEvent;
    onRightButtonClick = () => {
      setIsEdit(true);
    };
    leftButtonLabel = t('common.cancel');
    rightButtonLabel = '';
  }

  if (isEdit) {
    onLeftButtonClick = null;
    onRightButtonClick = () => {
      setIsTriedToSubmit(true);
      formik.handleSubmit();
    };
    leftButtonLabel = '';
    rightButtonLabel = '';
  }

  return (
    <div
      className={classNames(classes.blur, {
        [classes.blurActivated]: isEventDetailsVisible,
      })}
      onClick={closePopup}
    >
      <div
        className={classNames(classes.container, {
          [classes.visible]: isOpened,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.event}>
          <Header
            status={status}
            event={event}
            refetch={refetch}
            currentUser={currentUser}
            title={t(
              'dashboardComponents.Calendar.EventDetails.Joining.New Joiner'
            )}
          />

          <Details
            event={event}
            setIsCandidateModalVisible={setIsCandidateModalVisible}
            setCurrentCandidate={setCurrentCandidate}
          />

          <div className={classes.details}>
            {isEdit ? (
              <>
                <div className={classes.editContainer}>
                  <div className={classes.row}>
                    <div className={classes.col}>
                      <DatePicker
                        dateFieldWidth={isTabletOrMobile ? '100%' : 370}
                        height={45}
                        style={{
                          backgroundColor: 'white',
                        }}
                        labelStyle={{
                          fontSize: 14,
                        }}
                        label="Start Date"
                        name="joiningDate"
                        value={formik.values.joiningDate}
                        setFieldValue={formik.setFieldValue}
                        error={formik.errors.joiningDate}
                        // touched={isDelegatesTriedToSubmit}
                        setFieldTouched={formik.setFieldTouched}
                        isPopupOpened
                      />
                    </div>
                    <div className={classes.col}>
                      <TimeRange
                        labelStyle={{
                          fontSize: 14,
                        }}
                        style={{
                          backgroundColor: 'white',
                        }}
                        height={45}
                        fromValue={formik.values.startTime}
                        toValue={formik.values.endTime}
                        setFieldValue={formik.setFieldValue}
                        fromName="startTime"
                        toName="endTime"
                        error={formik.errors.endTime}
                        startTimeError={formik.errors.startTime}
                        touched={isTriedToSubmit}
                        label="Time"
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.col}>
                      <FormDropdown
                        height={45}
                        style={{
                          backgroundColor: 'white',
                        }}
                        //  width={350}
                        label={t(
                          'dashboardComponents.Calendar.EventDetails.Joining.Location'
                        )}
                        placeholder={t(
                          'dashboardComponents.Calendar.EventDetails.Joining.Select location'
                        )}
                        fetchOptions={{
                          dataName: 'restaurantsOptionsBasicInfo',
                          getDataHandler: AdminService.getRestaurants,
                        }}
                        value={formik.values.location}
                        infoWidth={151}
                        name="location"
                        searchInfo={t(
                          'dashboardComponents.Calendar.EventDetails.Joining.Add new Restaurant in Restaurant admin screen, if you cannot find one.'
                        )}
                        searchInfoWidth={188}
                        setFieldValue={formik.setFieldValue}
                      />
                    </div>
                    <div
                      className={classes.col}
                      style={{
                        position: 'relative',
                        top: -5,
                      }}
                    >
                      <MultiDropdown
                        height={45}
                        style={{
                          backgroundColor: 'white',
                        }}
                        maxLength={3}
                        note={t(
                          'dashboardComponents.Calendar.EventDetails.Joining.Select up to 3'
                        )}
                        //  width={350}
                        label={t(
                          'dashboardComponents.Calendar.EventDetails.Joining.Person to meet'
                        )}
                        fetchOptions={{
                          dataName: 'userOptionPostJobSuccess',
                          getDataHandler: AdminService.getUsers,
                          labelName: 'userName',
                          isUserList: true,
                        }}
                        value={formik.values.userIds}
                        name="userIds"
                        setFieldValue={formik.setFieldValue}
                        placeholder={t(
                          'dashboardComponents.Calendar.EventDetails.Joining.Select name and email address'
                        )}
                      />

                      <div className={classes.selectedUsers}>
                        <SelectedUsers
                          invitedUsers={formik.values.userIds}
                          withFormik
                          setInvitedUsers={(users) =>
                            formik.setFieldValue('userIds', users)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.col}>
                      <Textarea
                        style={{
                          backgroundColor: 'white',
                        }}
                        name="note"
                        onChange={formik.handleChange}
                        value={formik.values.note}
                        label={t(
                          'dashboardComponents.Calendar.EventDetails.Joining.Email message'
                        )}
                        note={t('common.optional')}
                        height={145}
                        placeholder={t(
                          'dashboardComponents.Calendar.EventDetails.Joining.Enter any notes you want to share in the email'
                        )}
                        // maxLength={5000}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <JoiningDetails event={event} />
            )}
          </div>

          {!isEdit && (
            <div className={classes.contactInfo}>
              <ContactInfo event={event} />
            </div>
          )}
        </div>
        <div className={classes.buttons}>
          <FormButtonsManager
            buttonStyle={{
              width: 250,
              height: 55,
            }}
            leftButtonLabel={leftButtonLabel}
            rightButtonLabel={rightButtonLabel}
            onLeftButtonClick={onLeftButtonClick}
            onRightButtonClick={onRightButtonClick}
            mode={isEdit ? 'edit' : 'view'}
            formik={formik}
            fields={yupSchemaToFields(validationSchema)}
          />
        </div>
        <div className={classes.closeIconContainer}>
          <i className={classes.closeIcon} onClick={closePopup}>
            Close
          </i>
        </div>
      </div>
      <div className={classes.bottom} />
    </div>
  );
}
