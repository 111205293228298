import React from 'react';

import { useTranslation } from 'react-i18next';

import FormButton from '../../FormButton';

import classes from './styles.module.scss';

export default function FormButtonsManager({
  formik,
  mode,
  onRightButtonClick,
  onLeftButtonClick,
  rightButtonLabel,
  leftButtonLabel,
  fields,
  buttonStyle,
  isRightButtonDisabled, // Only used in reviewIncident mode
  isLeftButtonDisabled, // Only used in reviewIncident mode
}) {
  const { t } = useTranslation();

  const isDirty = formik?.dirty;
  const isSubmitting = formik?.isSubmitting;

  let buttons = null;

  const style = {
    width: 252,
    height: 52,
    ...buttonStyle,
  };

  const isSubmitButtonDisabled = () => {
    if (isSubmitting || !isDirty) {
      return true;
    }

    const areAllRequiredFieldsFilled = !fields.some((field) => {
      if (
        field?.validation?.includes?.('required') &&
        !formik.values[field.field]
      ) {
        return true;
      }
      return false;
    });

    return !areAllRequiredFieldsFilled;
  };

  const resetForm = () => {
    formik.resetForm();
  };

  if (mode === 'add') {
    buttons = (
      <>
        <FormButton
          style={style}
          variant="reset"
          disabled={isSubmitting || !isDirty}
          isSubmitting={isSubmitting}
          onClick={resetForm}
        >
          {leftButtonLabel || t('common.clear')}
        </FormButton>
        <FormButton
          style={style}
          variant="submit"
          disabled={isSubmitButtonDisabled()}
          isSubmitting={isSubmitting}
          onClick={onRightButtonClick}
        >
          {rightButtonLabel || t('common.add')}
        </FormButton>
      </>
    );
  } else if (mode === 'edit') {
    buttons = (
      <>
        <FormButton
          style={style}
          variant="reset"
          disabled={!isDirty}
          onClick={resetForm}
        >
          {leftButtonLabel || t('common.reset')}
        </FormButton>
        <FormButton
          style={style}
          variant="submit"
          disabled={isSubmitButtonDisabled()}
          onClick={() => {
            onRightButtonClick();
          }}
        >
          {rightButtonLabel || t('common.save')}
        </FormButton>
      </>
    );
  } else if (mode === 'view') {
    buttons = (
      <>
        <FormButton style={style} variant="delete" onClick={onLeftButtonClick}>
          {leftButtonLabel || t('common.delete')}
        </FormButton>
        <FormButton style={style} variant="reset" onClick={onRightButtonClick}>
          {rightButtonLabel || t('common.edit')}
        </FormButton>
      </>
    );
  } else if (mode === 'reviewIncident') {
    buttons = (
      <>
        <FormButton
          style={style}
          variant="reset"
          onClick={onLeftButtonClick}
          disabled={isLeftButtonDisabled}
        >
          {t(
            'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.ClearIncident'
          )}
        </FormButton>
        <FormButton
          style={style}
          variant="deactivate"
          onClick={onRightButtonClick}
          disabled={isRightButtonDisabled}
        >
          {t(
            'dashboardComponents.Console.ContentManagement.Blockings.ViewBlocking.DeactivateUser'
          )}
        </FormButton>
      </>
    );
  }

  return <div className={classes.FormButtonsManager}>{buttons}</div>;
}
