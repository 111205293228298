import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

export default function useLinkedDropdownOptionsCallback({
  fetchHandler,
  queryKey,
  onFetchSuccess,
  enabled,
}) {
  const { data } = useQuery({
    queryKey,
    queryFn: fetchHandler,
    enabled: !!enabled,
  });

  useEffect(() => {
    if (data) {
      onFetchSuccess(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
}
