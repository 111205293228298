import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import FavStatus from '../../../Header/Search/FavStatus';

import classes from './styles.module.scss';

function getEventDate(date) {
  const tempDate = new Date(date);

  return `${tempDate.getDate()} ${tempDate.toLocaleString('en-us', {
    month: 'long',
  })} ${tempDate.getFullYear()}
  `;
}

export default function Header({
  status,
  event,
  refetch,
  currentUser,
  // isOnboarding,
  title,
}) {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  let eventDate = getEventDate(event?.eventDate);
  let eventTime = event?.time;

  if (status === 'ChangeRequest') {
    eventDate = getEventDate(event?.event?.requestedDate);
    eventTime = `${event?.event?.requestedStartTime} - ${event?.event?.requestedEndTime}`;
  }

  return (
    <div className={classes.Header}>
      <div className={classes.row}>
        <div className={classes.titleContainer}>
          <span className={classes.headline}>{title}</span>
          {(status === 'Accepted' ||
            status === 'Declined' ||
            status === 'ChangeRequest') && (
            <div className={classes.status}>
              <div
                className={classNames(classes.statusText, {
                  [classes.statusDeclined]: status === 'Declined',
                  [classes.changeRequest]: status === 'ChangeRequest',
                })}
              >
                {status.replace(/([A-Z])/g, ' $1').trim()}
              </div>
            </div>
          )}
          {isTabletOrMobile && (
            <FavStatus
              calendarEvent={event}
              width={30}
              userId={currentUser?.id}
              refetch={refetch}
            />
          )}
          {status !== 'ChangeRequest' && (
            <span className={classes.headerDate}>
              {eventDate} {eventTime}
            </span>
          )}
        </div>
        {!isTabletOrMobile && (
          <FavStatus
            calendarEvent={event}
            width={30}
            userId={currentUser?.id}
            refetch={refetch}
          />
        )}
      </div>
      <div className={classes.row}>
        {status === 'ChangeRequest' && (
          <span className={classes.statusDate}>
            {t(
              'dashboardComponents.Calendar.EventDetails.Onboarding.Requested'
            )}
            : {eventDate} {eventTime}
          </span>
        )}
      </div>
    </div>
  );
}
