import React from 'react';

import { useMediaQuery } from 'react-responsive';

import TextInput from '../../../../../Form/TextInput';

import classes from './styles.module.scss';

export default function Header({ inputs, formik, touched, isReadOnly }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className={classes.Header}>
      <div className={classes.inputs}>
        {inputs.map((input) => (
          <TextInput
            autoComplete="off"
            key={input.placeholder}
            width={isTabletOrMobile ? '100%' : 350}
            height={45}
            name={input.name}
            placeholder={input.placeholder}
            value={formik.values[input.name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors[input.name]}
            touched={touched}
            readonly={isReadOnly}
          />
        ))}
      </div>
    </div>
  );
}
