import React from 'react';

import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Textarea from '../../components/Form/Textarea';

import classes from './styles.module.scss';
import closeCross from '../../assets/images/modals/close-cross.svg';

export default function MessageModal({ show, handleClose, message }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.MessageModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <button
          className={classes.closeButton}
          type="button"
          onClick={handleClose}
        >
          <img src={closeCross} alt="Close" />
        </button>
        <div className={classes.textareaContainer}>
          <Textarea readonly width={462} height={205} value={message} />
        </div>
      </div>
    </Modal>
  );
}
