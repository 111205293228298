import React from 'react';

import { useTranslation } from 'react-i18next';

import Tooltip from '../../../components/Tooltip';

import classes from './styles.module.scss';
import greenEnvelope from '../../../assets/images/dashboard/jobs/offers/green-envelope.svg';
import grayEnvelope from '../../../assets/images/dashboard/jobs/offers/gray-envelope.svg';
import { useUiContext } from '../../../context/UiContext';
import useLocalStorage from '../../../hooks/useLocalStorage';

export default function CounterOfferMessage({ offer }) {
  const [readCandidateMessages, setReadCandidateMessages] = useLocalStorage(
    'readCandidateMessages',
    []
  );

  const { showMessageModal } = useUiContext();

  const { t } = useTranslation();

  const isRead = readCandidateMessages.includes(
    offer?.declineMessage + offer?.id
  );

  const markMessageAsRead = () => {
    setReadCandidateMessages((prev) => [
      ...prev,
      offer?.declineMessage + offer?.id,
    ]);
  };

  if (!offer?.declineMessage) {
    return null;
  }

  return (
    <Tooltip
      text={t('modals.OfferModal.messageFromCandidate')}
      direction="top"
      width={195}
    >
      <button
        className={classes.CounterOfferMessage}
        type="button"
        onClick={() => {
          showMessageModal({ message: offer?.declineMessage });
          markMessageAsRead();
        }}
      >
        <img src={isRead ? grayEnvelope : greenEnvelope} alt="Envelope" />
      </button>
    </Tooltip>
  );
}
