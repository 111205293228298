import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import TimeRange from '../../../components/Form/TimeRange';
import Textarea from '../../../components/Form/Textarea';
import SelectedUsers from '../../../components/Dashboard/SelectedUsers';
import MultiDropdown from '../../../components/Form/MultiDropdown';
// import DateRange from '../../../components/Form/DateRange';
import DatePicker from '../../../components/Form/DatePicker';

import AdminService from '../../../services/AdminService';
import classes from './styles.module.scss';

export default function Remote({
  formik,
  isPopupOpened,
  calendar,
  isTriedToSubmit,
  readOnly,
}) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(classes.Remote, {
        [classes.calendar]: calendar,
      })}
    >
      <div className={classes.row}>
        <DatePicker
          readonly={readOnly}
          dateFieldWidth={calendar ? '100%' : 350}
          height={calendar ? 45 : ''}
          labelStyle={{
            fontSize: 14,
          }}
          style={{
            backgroundColor: calendar ? 'white' : '',
          }}
          label={t('modals.SetOnboardingModal.InPerson.onboardingDate')}
          name="onboardingDate"
          value={formik.values.onboardingDate}
          setFieldValue={formik.setFieldValue}
          error={formik.errors.onboardingDate}
          // touched={isDelegatesTriedToSubmit}
          setFieldTouched={formik.setFieldTouched}
          isPopupOpened={isPopupOpened}
        />
        <TimeRange
          label={t('modals.SetOnboardingModal.Remote.onboardingTime')}
          readOnly={readOnly}
          style={{
            backgroundColor: calendar ? 'white' : '',
          }}
          labelStyle={{
            fontSize: 14,
          }}
          height={calendar ? 45 : ''}
          fromValue={formik.values.startTime}
          toValue={formik.values.endTime}
          setFieldValue={formik.setFieldValue}
          fromName="startTime"
          toName="endTime"
          error={formik.errors.endTime}
          touched={isTriedToSubmit}
          startTimeError={formik.errors.startTime}
        />
      </div>

      <div className={classes.row}>
        <Textarea
          readonly={readOnly}
          style={{
            backgroundColor: calendar ? 'white' : '',
          }}
          labelStyle={{
            lineHeight: '24px',
          }}
          name="connectionDetail"
          value={formik.values.connectionDetail}
          onChange={formik.handleChange}
          width="100%"
          height={155}
          label={t('modals.ScheduleInterviewModal.meetingLink')}
          placeholder={t('modals.ScheduleInterviewModal.connectionPlaceholder')}
        />

        <div className={classes.сontact}>
          <div className={classes.selectContainer}>
            <MultiDropdown
              readOnly={readOnly}
              style={{
                backgroundColor: calendar ? 'white' : '',
              }}
              maxLength={3}
              note={t('modals.SetOnboardingModal.Remote.selectUpTo3')}
              width={calendar ? '100%' : 350}
              height={calendar ? 45 : 50}
              label={t('modals.SetOnboardingModal.personToMeet')}
              fetchOptions={{
                dataName: 'userOptionPostJobSuccess',
                getDataHandler: AdminService.getUsers,
                labelName: 'userName',
                isUserList: true,
              }}
              value={formik.values.userIds}
              name="userIds"
              setFieldValue={formik.setFieldValue}
              placeholder={t('modals.SetOnboardingModal.Remote.selectName')}
            />
          </div>
          <div
            className={classNames(classes.selectedUsers, {
              [classes.hasUsers]: formik.values.userIds.length > 0,
            })}
          >
            <SelectedUsers
              style={{ width: 350 }}
              readOnly={readOnly}
              invitedUsers={formik.values.userIds}
              withFormik
              setInvitedUsers={(users) =>
                formik.setFieldValue('userIds', users)
              }
            />
          </div>
        </div>
      </div>

      <div className={classNames(classes.row, classes.textareaContainer)}>
        <Textarea
          readonly={readOnly}
          style={{
            backgroundColor: calendar ? 'white' : '',
          }}
          label={t('modals.SetOnboardingModal.Remote.emailMessage')}
          note={t('modals.SetOnboardingModal.Remote.optional')}
          height={112}
          placeholder={t('modals.SetOnboardingModal.Remote.enterNotes')}
          maxLength={5000}
          value={formik.values.note}
          onChange={formik.handleChange}
          error={formik.errors.note}
          name="note"
          // touched={isTriedToSubmit}
        />
      </div>
    </div>
  );
}
