/* eslint-disable no-unused-vars */
/* eslint-disable no-self-compare */
/* eslint-disable no-constant-condition */
/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';

import classNames from 'classnames';
import GoTopButton from './GoTopButton';
import Post from './Post';
import Dropdown from '../../../Form/Dropdown';
import SearchInput from '../../../Form/SearchInput';

import refreshIcon from '../../../../assets/images/dashboard/discover/refresh.svg';
import { getAuthData } from '../../../../helpers/authStorage';
import { useUserStore, useFeedStore } from '../../../../store';
import flattenPaginatedData from '../../../../helpers/flattenPaginatedData';
import useIntersectionObserverPagination from '../../../../hooks/useIntersectionObserverPagination';
import FeedService from '../../../../services/FeedService';
import useDebouncedSearchWithHistory from '../../../../hooks/useDebouncedSearchWithHistory';
import classes from './styles.module.scss';

export default function Feed({ setEditingPost, leftColRef }) {
  const [activeOption, setActiveOption] = useState({
    label: 'Show All',
    value: 'Show All',
  });
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { searchHistory, debouncedSearchTerm } = useDebouncedSearchWithHistory(
    searchTerm,
    'feedSearchHistory'
  );
  const userScopes = useUserStore((state) => state.userScopes);
  const setRefetchPosts = useFeedStore((state) => state.setRefetchPosts);
  const hasFetchedPosts = useFeedStore((state) => state.hasFechedPosts);
  const setHasFechedPosts = useFeedStore((state) => state.setHasFechedPosts);
  const unreadPostCount = useFeedStore((state) => state.unreadPostCount);
  const resetUnreadPostCount = useFeedStore(
    (state) => state.resetUnreadPostCount
  );

  const { userId } = getAuthData();

  const getPostType = (option) => {
    if (
      ['Message', 'Announcement', 'Appreciation', 'Poll'].includes(option.value)
    ) {
      return option.value;
    }
    return null;
  };

  const getUserFilter = (option) => {
    if (['MyContent', 'Hidden', 'Draft'].includes(option.value)) {
      return option.value;
    }
    return null;
  };

  const getNoContentMessage = (activeOption) => {
    let message = t('dashboardComponents.Discover.Feed.noActivities');

    if (activeOption === 'Draft') {
      message = t('dashboardComponents.Discover.Feed.noDraftContent');
    } else if (activeOption === 'My Content') {
      message = t('dashboardComponents.Discover.Feed.noPersonalPosts');
    }

    return message;
  };

  const {
    data,
    refetch: refetchPosts,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ['feedPosts', debouncedSearchTerm, activeOption.value],
    queryFn: ({ pageParam = 1 }) =>
      FeedService.getFeedPosts({
        pageNumber: pageParam,
        Term: debouncedSearchTerm,
        Type: getPostType(activeOption),
        UserFilter: getUserFilter(activeOption),
        Filters: 'isPinned:eq:false',
      }),
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    refetchOnWindowFocus: false,
    enabled: !hasFetchedPosts || activeOption.value !== 'Show All',
    onSuccess: () => {
      setHasFechedPosts(true);
      resetUnreadPostCount();
    },
  });

  useEffect(() => {
    if (data) {
      const postsWithPinned = flattenPaginatedData(data);

      // Filter posts of type 'Message' and further filter to get those with mentions
      const postsWithMessagesAndMentions = postsWithPinned.filter(
        (post) =>
          post.type === 'Message' && post.mentions && post.mentions.length > 0
      );

      // Exclude posts where author.id or any mention.id matches userId
      const postsFilteredByMentions = postsWithMessagesAndMentions.filter(
        (post) => {
          const isAuthorNotUser = post.author?.id !== +userId;
          const isNotMentioned = post.mentions.every(
            (mention) => mention.id !== +userId
          );
          return isAuthorNotUser && isNotMentioned;
        }
      );

      // Create a new array that excludes postsFilteredByMentions from postsWithPinned
      const postsExcludingFiltered = postsWithPinned.filter(
        (post) =>
          !postsFilteredByMentions.some(
            (filteredPost) => filteredPost.id === post.id
          )
      );

      const postsWithoutPinned = postsExcludingFiltered.filter(
        (post) => !post.isPinned
      );

      // Set the filtered posts to state
      setFilteredPosts(postsWithoutPinned);
    }
  }, [data, userId]);

  const { data: pinnedPosts, refetch: refetchPinnedPosts } = useQuery({
    queryKey: ['pinnedPosts'],
    queryFn: () => FeedService.getPinnedFeedPosts(),
    enabled: !hasFetchedPosts,
    onSuccess: () => {
      setHasFechedPosts(true);
      resetUnreadPostCount();
    },
  });

  const refetch = async () => {
    try {
      await Promise.all([refetchPosts(), refetchPinnedPosts()]);
    } catch (error) {
      console.log(error);
    }
  };

  const showAllFetchedPosts = useCallback(() => {
    refetchPosts();
    refetchPinnedPosts();
  }, [refetchPinnedPosts, refetchPosts]);

  useEffect(() => {
    setRefetchPosts(showAllFetchedPosts);
  }, [setRefetchPosts, showAllFetchedPosts]);

  const { lastListElementRef } = useIntersectionObserverPagination({
    isFetching,
    fetchNextPage,
  });

  const options = [
    {
      label: t('dashboardComponents.Discover.Feed.all'),
      value: 'Show All',
    },
    {
      label: t('dashboardComponents.Discover.Feed.announcement'),
      value: 'Announcement',
    },
    {
      label: t('dashboardComponents.Discover.Feed.appreciation'),
      value: 'Appreciation',
    },
    { label: t('dashboardComponents.Discover.Feed.poll'), value: 'Poll' },
    {
      label: t('dashboardComponents.Discover.Feed.myContent'),
      value: 'MyContent',
      withDivider: true,
    },
    { label: t('dashboardComponents.Discover.Feed.draft'), value: 'Draft' },
    { label: t('dashboardComponents.Discover.Feed.hidden'), value: 'Hidden' },
  ];

  return (
    <div className={classes.Feed}>
      <div className={classes.header}>
        <h2>{t('dashboardComponents.Discover.Feed.feed')}</h2>
        <div className={classes.filters}>
          {unreadPostCount > 0 &&
          !debouncedSearchTerm &&
          activeOption.value === 'Show All' ? (
            <button
              type="button"
              className={classNames(classes.newPostCountButton, {
                [classes.pulseAnimation]: unreadPostCount > 1,
              })}
              onClick={showAllFetchedPosts}
              disabled={isFetching}
            >
              <span>
                {unreadPostCount}{' '}
                {!isTabletOrMobile && (
                  <>
                    {unreadPostCount === 1
                      ? t('dashboardComponents.Discover.Feed.newItem')
                      : t('dashboardComponents.Discover.Feed.newItems')}
                  </>
                )}
              </span>
              <img
                src={refreshIcon}
                alt={t('dashboardComponents.Discover.Feed.refresh')}
              />
            </button>
          ) : null}
          <SearchInput
            style={{
              backgroundColor: 'white',
            }}
            width={isTabletOrMobile ? 'calc(100% - 139px)' : 230}
            searchHistory={searchHistory}
            value={searchTerm}
            setValue={setSearchTerm}
          />
          <Dropdown
            activeOption={activeOption}
            setActiveOption={setActiveOption}
            options={options}
            width={isTabletOrMobile ? 139 : 150}
          />
        </div>
      </div>
      <div className={classes.content}>
        {!filteredPosts || !filteredPosts?.length ? (
          <div className={classes.noContent}>
            <div>{getNoContentMessage(activeOption.value)}</div>
          </div>
        ) : (
          <div className={classes.posts}>
            {activeOption.value === 'Show All'
              ? pinnedPosts?.map((feedPost) => {
                  return (
                    <Post
                      attachments={feedPost?.attachments?.filter(
                        (attach) =>
                          !attach.mimeType.includes('image') &&
                          !attach.mimeType.includes('video')
                      )}
                      isAuthor={feedPost.author?.id === +userId}
                      isAdmin={userScopes.includes('api:full')}
                      setEditingPost={setEditingPost}
                      refetch={refetch}
                      feedPost={feedPost}
                      key={feedPost.id}
                    />
                  );
                })
              : null}
            {filteredPosts?.map((feedPost, index) => {
              return (
                <Post
                  attachments={feedPost?.attachments?.filter(
                    (attach) =>
                      !attach.mimeType.includes('image') &&
                      !attach.mimeType.includes('video')
                  )}
                  isAuthor={feedPost.author?.id === +userId}
                  isAdmin={userScopes.includes('api:full')}
                  setEditingPost={setEditingPost}
                  refetch={refetch}
                  feedPost={feedPost}
                  key={feedPost.id}
                  reference={
                    filteredPosts?.length === index + 1
                      ? lastListElementRef
                      : null
                  }
                  isHiddenTabActive={activeOption.value === 'Hidden'}
                />
              );
            })}
          </div>
        )}
      </div>
      <GoTopButton scrollableContainerRef={leftColRef} />
    </div>
  );
}
