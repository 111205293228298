import axios from 'axios';

import { JOBS_API_URL_V2, JOBS_API_URL, CMS_API_URL } from '../constants/main';

import { getNonPaginatedData, getData } from '../helpers/requestData';

const CandidatesService = {
  async getCandidate(id) {
    const response = await axios.get(`${JOBS_API_URL}/candidates/${id}`);
    return response.data;
  },

  async getCandidates({
    term,
    pageNumber,
    pageSize,
    orderBy,
    sortOrder,
    Filters,
  }) {
    const url = `${JOBS_API_URL_V2}/candidates`;
    return getData({
      term,
      pageNumber,
      pageSize,
      orderBy,
      sortOrder,
      url,
      Filters,
    });
  },

  async getAllCandidates({
    term,
    pageNumber,
    pageSize = 9999,
    orderBy,
    sortOrder,
    isFavorite,
    Filters,
  }) {
    const url = `${JOBS_API_URL_V2}/candidates`;
    return getNonPaginatedData({
      term,
      pageNumber,
      pageSize,
      orderBy,
      sortOrder,
      url,
      isFavorite,
      Filters,
    });
  },

  async getCandidatesCounts() {
    const response = await axios.get(`${JOBS_API_URL}/candidates/counts`);
    return response.data;
  },

  async checkCandidateEmail(email) {
    const response = await axios.post(
      `${JOBS_API_URL_V2}/candidates/validate`,
      { email }
    );
    return response.data;
  },

  async createCandidate({
    firstName,
    lastName,
    email,
    phone,
    birthDate,
    city,
    userProfileGraduation,
    sourceName,
    userProfileExperiences,
    userProfileFileResume,
    jobPostId,
    applicationStatus,
    applicationSubStatus,
    otherSourceName,
  }) {
    const sourceNameValue = sourceName.value;
    const userProfileGraduationValue = {
      id: userProfileGraduation.value,
      name: userProfileGraduation.label,
    };

    const data = {
      firstName,
      lastName,
      email,
      phone,
      birthDate,
      city,
      userProfileGraduation: userProfileGraduationValue,
      sourceType: 'External',
      sourceName: sourceNameValue,
      userProfileExperiences,
      userProfileFileResume: {
        ...userProfileFileResume,
        fileName: userProfileFileResume.displayName,
      },
      jobPostId: jobPostId?.value,
      applicationStatus: applicationStatus?.value,
      applicationSubStatus: applicationSubStatus || '',
      otherSourceName,
    };

    if (!data.jobPostId) {
      delete data.applicationSubStatus;
    }

    const response = await axios.post(`${JOBS_API_URL_V2}/candidates`, data);
    return response.data;
  },

  async updateCandidate({
    id,
    firstName,
    lastName,
    email,
    phone,
    birthDate,
    city,
    userProfileGraduation,
    sourceName,
    userProfileExperiences,
    userProfileFileResume,
    jobPostId,
    applicationStatus,
    applicationSubStatus,
    otherSourceName,
  }) {
    const sourceNameValue = sourceName.value || sourceName;
    const userProfileGraduationValue = {
      id: userProfileGraduation.value,
      name: userProfileGraduation.label,
    };

    const data = {
      firstName,
      lastName,
      email,
      phone,
      birthDate,
      city,
      userProfileGraduation: userProfileGraduationValue,
      sourceName: sourceNameValue,
      userProfileExperiences,
      userProfileFileResume: {
        ...userProfileFileResume,
        fileName:
          userProfileFileResume.displayName || userProfileFileResume.fileName,
      },
      sourceType: 'External',
      jobPostId: jobPostId?.value,
      applicationStatus: applicationStatus?.value,
      applicationSubStatus,
      otherSourceName,
    };

    if (!data.jobPostId) {
      delete data.applicationSubStatus;
    }

    const response = await axios.put(`${JOBS_API_URL}/candidates/${id}`, data);
    return response.data;
  },

  async uploadResume(file, progressHandler) {
    const form = new FormData();
    form.append('File', file);
    // const response = await axios.post(`${CMS_API_URL}/uploads/files`, form);

    const response = await axios.post(`${CMS_API_URL}/uploads/files`, form, {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        progressHandler(percent);
      },
    });

    return response.data;
  },

  async deleteCandidate(id) {
    const response = await axios.delete(`${JOBS_API_URL}/candidates/${id}`);
    return response.data;
  },

  async getJobPost(id) {
    const response = await axios.get(`${JOBS_API_URL}/jobposts/${id}`);
    return response.data;
  },
};

export default CandidatesService;
