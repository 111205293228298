/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-handler-names */
import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

import Buttons from './Buttons';
import CounterOfferStatus from './CounterOfferStatus';
import CurrentOffer from './CurrentOffer';
import PrevOffer from './PrevOffer';
import DocUploader from '../../components/Dashboard/Console/JobManagement/JobTemplates/Template/BasicInfo/DocUploader';
import TextInput from '../../components/Form/TextInput';
import Textarea from '../../components/Form/Textarea';
import FormDropdown from '../../components/Form/FormDropdown';
import CandidateOfferInfo from './CandidateOfferInfo';

import useOfferStatus from './hooks/useOfferStatus';
import AdminService from '../../services/AdminService';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  currency: yup.object().required('This field is required'),
  amount: yup.string().trim().required('This field is required'),
  location: yup.object().required('This field is required'),
  note: yup.string().trim(),
  customJobTitle: yup.string().trim(),
});

export default function OfferModal({
  show,
  handleClose,
  candidate,
  refetch,
  //  readOnly,
}) {
  const [, setIsTriedToSubmit] = useState(false);
  const [isJobTitleEditMode, setIsJobTitleEditMode] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [counterOfferMode, setCounterOfferMode] = useState('default');

  const { showNotification, setIsFetching, showModal } = useContext(UiContext);

  const { t } = useTranslation();

  const { data: job } = useQuery({
    queryKey: ['myJob', candidate?.jobPostId],
    queryFn: () => AdminService.getJobPost({ id: candidate?.jobPostId }),
    enabled: !!candidate?.jobPostId,
  });

  const closeAndNotify = (message) => {
    handleClose();
    refetch();
    showNotification({
      text: message,
    });
  };

  const { offer, readOnly, allowedToEdit, isCountered } = useOfferStatus({
    candidate,
    counterOfferMode,
  });

  // Clean up
  useEffect(() => {
    if (!show) {
      setTimeout(() => setCounterOfferMode('default'), 300);
    }
  }, [show]);

  useEffect(() => {
    if (offer && offer.jobApplicationOfferAttachments?.length) {
      setAttachment(offer.jobApplicationOfferAttachments);
    }
  }, [offer, show]);

  const formik = useFormik({
    initialValues: {
      currency: offer?.currency
        ? { label: offer.currency, value: offer.currency }
        : { label: 'TRY', value: 'TRY' },
      amount: offer?.amount?.toString() || '',
      location: offer?.location
        ? { label: offer.location?.name, value: offer.location?.id }
        : '',
      note: offer?.note || '',
      customJobTitle: offer?.customJobTitle || job?.jobTitle || '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFetching(true);

        let uploadedFile;

        if (attachment && !attachment.length) {
          uploadedFile = await AdminService.uploadFile(attachment);
        }

        const offerData = {
          jobApplicationId: candidate.id,
          currency: values.currency.label,
          amount:
            !isNaN(values.amount) && !isNaN(parseFloat(values.amount))
              ? values.amount
              : parseFloat(values.amount.replaceAll(',', '')),
          locationName: values.location.label,
          locationId: values.location.value,
          note: values.note,
          customJobTitle: values.customJobTitle,
        };

        if (isCountered && counterOfferMode === 'decline') {
          offerData.status = 'Declined';
          // offerData.declineReason = 'RecruiterDecision';
          // console.log('OFFFER DATA', offerData);
          // return;
        }

        if (isCountered && counterOfferMode === 'update') {
          offerData.status = 'Countered';
          offerData.declineReason = 'CounterOffer';
        }

        if (
          isCountered &&
          counterOfferMode === 'update' &&
          offer.counterAmount === offerData.amount
        ) {
          offerData.status = 'CounterAccepted';
        }

        if (uploadedFile) {
          offerData.jobApplicationOfferAttachments = [
            {
              id: uploadedFile.id,
              fileName: uploadedFile.fileName,
              url: uploadedFile.uri,
              mimeType: uploadedFile.mimeType,
              displayName: uploadedFile.displayName,
            },
          ];
        }

        if (offer) {
          await AdminService.updateJobApplicationOffer({
            ...offerData,
            id: offer.id,
          });
        } else {
          await AdminService.createJobApplicationOffer(offerData);
        }

        if (!isCountered) {
          await AdminService.changeMyJobApplicationStatus({
            jobApplicationId: candidate.id,
            status: 'Offer',
            subStatus: 'Offered',
          });
        }

        await refetch();
        handleClose();

        if (offer && counterOfferMode !== 'decline') {
          closeAndNotify(t('modals.OfferModal.offerUpdated'));
        } else if (offer && counterOfferMode === 'decline') {
          handleClose();
          showModal({
            title: t('modals.OfferModal.declineCounterOfferConfirmationTitle'),
            text: t('modals.OfferModal.declineCounterOfferConfirmationText'),
            dissmissButtonLabel: t('common.gotIt'),
          });
        } else {
          closeAndNotify(t('modals.OfferModal.offerCreated'));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!show) {
      formik.resetForm();
      setIsTriedToSubmit(false);
      setIsJobTitleEditMode(false);
      setAttachment(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const isDisabled =
    !formik.values.location ||
    !formik.values.amount ||
    readOnly ||
    !formik.dirty;

  const userLocation =
    candidate?.applicantLocation ||
    candidate?.user?.userProfile?.city ||
    candidate?.user?.userProfile?.customLocation;

  let modalTitle = t('modals.OfferModal.modalTitle');

  if (isCountered && !readOnly) {
    modalTitle = t('modals.OfferModal.offer.counterOffer');
  }

  if (readOnly) {
    modalTitle = t('modals.OfferModal.offer.view');
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={classes.OfferModal}
      backdropClassName={classes.backdrop}
      contentClassName={classes.modalContent}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <header className={classes.header}>
          <h1>{modalTitle}</h1>
          <i className={classes.closeIcon} onClick={handleClose}>
            Close
          </i>
        </header>
        <div className={classes.container}>
          <div className={classes.row}>
            <div className={classes.col}>
              <div className={classes.applicationInfo}>
                <h3>{t('modals.OfferModal.candidate')}</h3>
                <h2>
                  {candidate?.user?.userProfile?.firstName}{' '}
                  {candidate?.user?.userProfile?.lastName}
                </h2>
                {isCountered && !readOnly ? (
                  <CounterOfferStatus offer={offer} status={counterOfferMode} />
                ) : (
                  // <span className={classes.location}>{userLocation}</span>
                  <CandidateOfferInfo
                    candidate={candidate}
                    userLocation={userLocation}
                    counterOfferMode={counterOfferMode}
                  />
                )}
              </div>
            </div>
            <div className={classes.col}>
              <div className={classes.applicationInfo}>
                {!isJobTitleEditMode && !readOnly && (
                  // Always hidden via styles
                  <i
                    className={classes.editIcon}
                    onClick={() => setIsJobTitleEditMode(true)}
                  >
                    Edit
                  </i>
                )}
                <h3>{t('modals.OfferModal.position.header')}</h3>
                {isJobTitleEditMode ? (
                  <TextInput
                    height={48}
                    value={formik.values.customJobTitle}
                    name="customJobTitle"
                    onChange={formik.handleChange}
                  />
                ) : (
                  <h2 className={classes.jobTitle}>{job?.jobTitle}</h2>
                )}
                <span className={classes.jobId}>
                  {t('modals.OfferModal.position.jobId')}:{' '}
                  {candidate?.jobPostId}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <CurrentOffer
                notAllowedToEdit={!allowedToEdit}
                formik={formik}
                status={counterOfferMode}
                readOnly={readOnly}
                candidate={candidate}
              />
            </div>
            <div className={classes.col}>
              <PrevOffer
                candidate={candidate}
                counterOfferMode={counterOfferMode}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.col}>
              <FormDropdown
                readonly={!allowedToEdit}
                width={350}
                label={t('modals.OfferModal.jobLocation.label')}
                placeholder={t('modals.OfferModal.jobLocation.placeholder')}
                fetchOptions={{
                  dataName: 'restaurantsOptionsBasicInfo',
                  getDataHandler: AdminService.getRestaurants,
                }}
                value={formik.values.location}
                infoWidth={151}
                name="location"
                searchInfo={t('modals.OfferModal.jobLocation.info')}
                searchInfoWidth={188}
                setFieldValue={formik.setFieldValue}
              />
              <div className={classes.docUploaderContainer}>
                <DocUploader
                  file={attachment}
                  setFile={setAttachment}
                  readonly={!allowedToEdit}
                  filename={
                    !allowedToEdit
                      ? offer?.jobApplicationOfferAttachments?.[0]?.displayName
                      : ''
                  }
                />
              </div>
            </div>
            <div className={classes.col}>
              <Textarea
                readonly={!allowedToEdit}
                name="note"
                onChange={formik.handleChange}
                value={formik.values.note}
                label={t('modals.OfferModal.emailLabel')}
                note={t('modals.OfferModal.emailNote')}
                height={226}
                placeholder={t('modals.OfferModal.emailPlaceholder')}
                // maxLength={5000}
              />
            </div>
          </div>
        </div>
        <Buttons
          formik={formik}
          readOnly={readOnly}
          isDisabled={isDisabled}
          setIsTriedToSubmit={setIsTriedToSubmit}
          counterOfferMode={counterOfferMode}
          setCounterOfferMode={setCounterOfferMode}
          handleClose={handleClose}
          candidate={candidate}
        />
      </div>
    </Modal>
  );
}
