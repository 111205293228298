import React from 'react';

import { get } from 'lodash';

import TextInput from '../../../../Form/TextInput';
import DatePicker from '../../../../Form/DatePicker';
import FormDropdown from '../../../../Form/FormDropdown';
import PhoneInput from '../../../../Form/PhoneInput';

import { externalSources } from './config';

const createVisibilityValue = (isHidden, values) => {
  return isHidden(values) === true ? 'hidden' : 'visible';
};

const createDisplayValue = (shouldRender, values) => {
  return shouldRender(values) === true ? 'block' : 'none';
};

export const createInitialValues = (
  detailsMainInputs,
  nameInputs,
  currentCandidate
) => {
  const allInputs = [...detailsMainInputs, ...nameInputs];

  if (!currentCandidate) {
    return {
      ...allInputs.reduce((acc, input) => {
        acc[input.name] = input.initialValue || '';
        return acc;
      }, {}),
      userProfileExperiences: [],
      userProfileFileResume: '',
      jobPostId: '',
      applicationStatus: '',
      applicationSubStatus: '',
    };
  }

  const initialValues = {
    ...allInputs.reduce((acc, input) => {
      acc[input.name] = currentCandidate?.userProfile?.[input.name];
      return acc;
    }, {}),
    userProfileExperiences: currentCandidate?.userProfileExperiences,
  };

  if (initialValues.birthDate) {
    initialValues.birthDate = new Date(initialValues.birthDate);
  }
  if (initialValues.userProfileGraduation) {
    initialValues.userProfileGraduation = {
      label: initialValues.userProfileGraduation.name,
      value: initialValues.userProfileGraduation.id,
    };
  }

  if (currentCandidate.userProfile?.userProfileExperiences?.length) {
    initialValues.hasExperience = { label: 'Yes', value: true };
    initialValues.userProfileExperiences =
      currentCandidate.userProfile.userProfileExperiences;
  } else {
    initialValues.hasExperience = { label: 'No', value: false };
    initialValues.userProfileExperiences = [];
  }

  /* initialValues.sourceName = {
    label: currentCandidate?.sourceName,
    value: currentCandidate?.sourceName,
  };
 */

  // console.log('currentCandidate', currentCandidate);

  if (currentCandidate.sourceName === 'Other Sources') {
    initialValues.source = {
      label: 'Other Source',
      value: 'Other Source',
    };
    initialValues.externalSource = {
      label: 'Other Sources',
      value: 'Other Sources',
    };
    initialValues.otherSourceName = currentCandidate?.otherSourceName;
  } else if (
    externalSources.map((s) => s.value).includes(currentCandidate.sourceName)
  ) {
    initialValues.source = {
      label: 'Other Source',
      value: 'Other Source',
    };
    initialValues.externalSource = {
      label: currentCandidate?.sourceName,
      value: currentCandidate?.sourceName,
    };
  } else {
    initialValues.source = {
      label: currentCandidate?.sourceName,
      value: currentCandidate?.sourceName,
    };
  }

  initialValues.userProfileFileResume =
    currentCandidate?.userProfile?.userProfileFileResume;

  // Job application values
  if (currentCandidate.jobApplications?.length) {
    initialValues.jobPostId = {
      label: currentCandidate.jobApplications[0].jobPost.jobTitle,
      value: currentCandidate.jobApplications[0].jobPost.id,
    };
    initialValues.applicationStatus = {
      label: currentCandidate.jobApplications[0].status,
      value: currentCandidate.jobApplications[0].status,
    };
    initialValues.applicationSubStatus =
      currentCandidate.jobApplications[0].subStatus;
  }

  return initialValues;
};

export const createExperienceSubFormInitailValues = (experienceInputs) =>
  experienceInputs.reduce((acc, input) => {
    acc[input.name] = '';
    return acc;
  }, {});

export const areAllRequiredFieldsFilled = (formik, validationSchema) => {
  const isFieldRequired = (fieldName) => {
    return (
      validationSchema.fields[fieldName]?.exclusiveTests?.required ||
      formik.errors[fieldName] === 'This field is required'
    );
  };

  if (
    formik.values.hasExperience.value === true &&
    formik.values.userProfileExperiences.some((exp) =>
      Object.values(exp).some((val) => !val)
    )
  ) {
    return false;
  }

  /* Object.keys(validationSchema.fields).forEach((fieldName) => {
    console.log(
      fieldName,
      isFieldRequired(fieldName),
      formik.values[fieldName]
    );
  }); */

  return Object.keys(validationSchema.fields).every((fieldName) => {
    return !isFieldRequired(fieldName) || !!formik.values[fieldName];
  });
};

export function renderInputs({
  input,
  formik,
  warnings,
  inputWidth = '100%',
  customName,
  touched,
  validatedEmail,
  isReadOnly,
}) {
  const name = customName || input.name;
  const inputHeight = 45;
  const labelStyle = {
    fontSize: 14,
    fontFamily: 'Gilroy-Regular',
    fontWeight: 'normal',
    color: '#C1C5D6',
    lineHeight: '20px',
  };

  let isTouched = touched;

  if (input.name === 'email') {
    isTouched = !validatedEmail?.isValid ? true : touched;
  }

  let error = get(formik.errors, name);

  if (input.name === 'email' && !validatedEmail?.isValid) {
    error = 'Duplicate email. Account already exists.';
  }

  const isDisabled = !validatedEmail?.isValid && input.name !== 'email';

  switch (input.type) {
    case 'text':
      return (
        <div
          style={{
            visibility: input?.isHidden
              ? createVisibilityValue(input.isHidden, formik.values)
              : 'visible',
            display: input.shouldRender
              ? createDisplayValue(input.shouldRender, formik.values)
              : 'block',
          }}
        >
          <TextInput
            autoComplete="off"
            key={name}
            placeholder={input.placeholder}
            label={input.label}
            width={inputWidth}
            height={inputHeight}
            labelStyle={labelStyle}
            value={get(formik.values, name)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={error}
            touched={isTouched}
            name={name}
            staticErrorPositon
            readonly={isDisabled || isReadOnly}
          />
        </div>
      );
    case 'phone':
      return (
        <PhoneInput
          name={name}
          label={input.label}
          placeholder={input.placeholder}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={isTouched}
          error={error}
          value={get(formik.values, name)}
          height={inputHeight}
          readOnly={isDisabled || isReadOnly}
        />
      );
    case 'date':
      return (
        <DatePicker
          key={name}
          placeholder={input.placeholder}
          label={input.label}
          width={inputWidth}
          dateFieldWidth={inputWidth}
          height={inputHeight}
          labelStyle={labelStyle}
          calendarPosition="right"
          name={name}
          value={get(formik.values, name)}
          setFieldValue={formik.setFieldValue}
          touched={isTouched}
          error={error}
          setFieldTouched={formik.setFieldTouched}
          onlyPastDates
          warning={warnings?.[name]}
          staticErrorPositon
          readonly={isDisabled || isReadOnly}
          newCalendar
          calendarDefaultDate={input.calendarDefaultDate}
        />
      );
    case 'select':
      return (
        <div
          style={{
            visibility: input?.isHidden
              ? createVisibilityValue(input.isHidden, formik.values)
              : 'visible',

            display: input.shouldRender
              ? createDisplayValue(input.shouldRender, formik.values)
              : 'block',
          }}
        >
          <FormDropdown
            key={name}
            placeholder={input.placeholder}
            label={input.label}
            width={inputWidth}
            height={inputHeight}
            labelStyle={labelStyle}
            fetchOptions={input.fetchOptions}
            options={input.options}
            name={name}
            value={formik.values[name]}
            error={error}
            touched={isTouched}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
            readonly={isDisabled || isReadOnly}
          />
        </div>
      );
    default:
      return null;
  }
}
