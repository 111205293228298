import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../../../../../../Tooltip';
import CandidateMenu from './CandidateMenu';
import CandidateFavorites from '../../../CandidateFavorites';
import ResumeIcon from './ResumeIcon';
import JobAppViewNotificationIcon from '../../../../../../JobAppViewNotificationIcon';

import getTimeAgo from '../../../../../../../../helpers/getTimeAgo';
import { JOB_APPLICATION_STATUSES } from '../../../../../../../../constants/main';
import classes from './styles.module.scss';
import JobInfo from '../../../../../../Favorites/Candidates/CandidateCard/JobInfo';
import { getTimezoneDate } from '../../../../../../../../helpers/timezones';
import UserService from '../../../../../../../../services/UserService';
import getResizedImageUrl from '../../../../../../../../helpers/getResizedImageUrl';

const viewIconStyle = {
  position: 'relative',
  top: -2,
  marginLeft: 6,
};

const statusesColors = {
  Invited: {
    Pending: 'grey',
    Withdrawn: 'red',
    Rejected: 'red',
  },
  New: {
    Pending: 'grey',
    Rejected: 'red',
    Declined: 'red',
    Invited: 'green',
    Withdrawn: 'red',
  },
  Screen: {
    Requested: 'grey',
    Passed: 'green',
    Completed: 'green',
    AutoRejected: 'red',
    Incomplete: 'red',
    Withdrawn: 'red',
    Failed: 'red',
    Rejected: 'red',
  },
  Interview: {
    Pending: 'grey',
    Scheduled: 'black',
    Accepted: 'black',
    Passed: 'green',
    Rejected: 'red',
    Declined: 'red',
    Canceled: 'orange',
    ChangeRequested: 'orange',
    Withdrawn: 'red',
    Rescheduled: 'black',
  },
  Offer: {
    Pending: 'grey',
    Offered: 'black',
    Rejected: 'red',
    Declined: 'red',
    Accepted: 'green',
    Countered: 'orange',
    Withdrawn: 'red',
  },
  Onboard: {
    Pending: 'grey',
    Scheduled: 'black',
    NoShow: 'red',
    Onboarded: 'green',
    Canceled: 'orange',
    Declined: 'red',
    Rejected: 'red',
    ChangeRequest: 'orange',
    ChangeOnboard: 'orange',
    Accepted: 'green',
    Confirmed: 'green',
    StartDeclined: 'red',
    ChangeRequested: 'orange',
    StartCanceled: 'orange',
    StartDateSet: 'black',
    StartConfirmed: 'green',
    Withdrawn: 'red',
  },
};

export default function CandidateCard({
  candidate,
  refetch,
  changeJobApplicationStatus,
  showCandidatePopup,
  jobStatus,
  hasAssessment,
  resumeType,
  fullData,
}) {
  const [isDragged, setIsDragged] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const drag = (event) => {
    setIsDragged(true);
    event.dataTransfer.setData('jobApplicationId', candidate.id);
    event.dataTransfer.setData('candidate', JSON.stringify(candidate));
    event.dataTransfer.setData(
      'prevStatus',
      JSON.stringify({
        status: candidate.status,
        subStatus: candidate.subStatus,
      })
    );
  };

  const { t } = useTranslation();

  const { data: me } = useQuery({
    queryKey: ['me'],
    queryFn: UserService.getMe,
  });

  // eslint-disable-next-line no-shadow
  const getCandidateCardStatus = (candidate) => {
    let status = candidate.subStatus.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' ');
    if (status === 'Pending') {
      status = `${status} ${JOB_APPLICATION_STATUSES[candidate.status]}`;
    }

    if (
      (status === 'Scheduled' && candidate.status === 'Onboard') ||
      (status === 'Confirmed' && candidate.status === 'Onboard')
    ) {
      const onboardingDate =
        candidate.jobApplicationOnboardings?.[0]?.onboardingDate;
      status = (
        <span className={classes.scheduledDate}>
          {status}:{' '}
          <span className={classes.date}>
            {moment(
              getTimezoneDate(onboardingDate, me?.userProfile?.timezone)[0]
            ).format('DD-MMM-YYYY')}
          </span>
          <JobAppViewNotificationIcon
            style={viewIconStyle}
            jobApplication={candidate}
            isSeen={fullData?.onboardingSeenAt}
          />
        </span>
      );
    }

    if (
      (status === 'Start Date Set' && candidate.status === 'Onboard') ||
      (status === 'Start Confirmed' && candidate.status === 'Onboard')
    ) {
      const joiningDate = candidate.jobApplicationJoinings?.at(-1)?.joiningDate;
      status = (
        <span className={classes.scheduledDate}>
          {status === 'Start Date Set'
            ? status
            : t(
                'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.confirmed'
              )}
          :{' '}
          <span className={classes.date}>
            {moment(
              getTimezoneDate(joiningDate, me?.userProfile?.timezone)[0]
            ).format('DD-MMM-YYYY')}
          </span>
          <JobAppViewNotificationIcon
            style={viewIconStyle}
            jobApplication={candidate}
            isSeen={fullData?.joiningSeenAt}
          />
        </span>
      );
    }

    if (
      (status === 'Scheduled' && candidate.status === 'Interview') ||
      (status === 'Accepted' && candidate.status === 'Interview') ||
      (status === 'Rescheduled' && candidate.status === 'Interview')
    ) {
      const inteviewDate =
        candidate.jobApplicationInterviews?.[0]?.interviewDate;
      status = (
        <span className={classes.scheduledDate}>
          {status}:{' '}
          <span className={classes.date}>
            {moment(
              getTimezoneDate(inteviewDate)[0],
              me?.userProfile?.timezone
            ).format('DD-MMM-YYYY')}
          </span>
        </span>
      );
    }

    if (candidate.status === 'Invited' && candidate.subStatus !== 'Rejected') {
      status = <span className={classes.invitedStatus}>Invited</span>;
    }

    if (status === 'Accepted' && candidate.status === 'Onboard') {
      const onboardingDate =
        candidate.jobApplicationOnboardings?.[0]?.onboardingDate;
      status = (
        <span className={classes.scheduledDate}>
          {t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.confirmed'
          )}
          : {'  '}
          <span className={classes.date}>
            {moment(
              getTimezoneDate(onboardingDate, me?.userProfile?.timezone)[0]
            ).format('DD-MMM-YYYY')}
          </span>
          <JobAppViewNotificationIcon
            style={viewIconStyle}
            jobApplication={candidate}
            isSeen={fullData?.onboardingSeenAt}
          />
        </span>
      );
    }

    if (status === 'Change Requested' && candidate.status === 'Onboard') {
      status = candidate.jobApplicationJoinings.length
        ? t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.changeStart'
          )
        : t(
            'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.changeOnboard'
          );
    }

    if (status === 'Failed' && candidate.status === 'Screen') {
      status = t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.autoReject'
      );
    }

    if (
      status === 'Canceled' &&
      candidate.status === 'Onboard' &&
      candidate.jobApplicationJoinings.length
    ) {
      status = t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.startCanceledStatus'
      );
    } else if (status === 'Canceled' && candidate.status === 'Onboard') {
      status = t(
        'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.onboardCanceledStatus'
      );
    }

    return status;
  };

  const hasPhoto = !!candidate.user?.userProfile.avatar;

  const daysFromCreation = moment(new Date()).diff(
    moment(candidate.statusChangedAt || candidate.createdAt),
    'days'
  );
  let dateIconColor = '#C1C5D6';

  if (candidate.subStatus !== 'Rejected' && candidate.subStatus !== 'NoShow') {
    if (daysFromCreation > 6) {
      dateIconColor = '#FFBF00';
    }
    if (daysFromCreation > 13) {
      dateIconColor = '#FF2D55';
    }
  }

  const candidateCardStatus = getCandidateCardStatus(candidate);

  let colorClass = statusesColors[candidate?.status][candidate?.subStatus];

  // Change status color to red if candidate has expired flowQ forms
  if (
    candidateCardStatus ===
    t(
      'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.incomplete'
    )
  ) {
    colorClass = 'red';
  }

  return (
    <div
      className={classes.container}
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {isDragged && <div className={classes.gradientBorder} />}
      {mouseOver && <div className={classes.gradientBorder} />}
      <div
        className={classNames(classes.CandidateCard, {
          [classes.dragged]: isDragged,
          [classes.joined]: candidate?.subStatus === 'Joined',
        })}
        draggable
        onDragStart={drag}
        onDragEnd={() => setIsDragged(false)}
        onClick={() => showCandidatePopup(candidate?.id)}
      >
        <div className={classes.favoritesContainer}>
          <Tooltip
            text={t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.favorites'
            )}
          >
            <CandidateFavorites
              favoritesList={candidate?.jobApplicationFavorites}
              refetch={refetch}
              jobApplicationId={candidate.id}
              white={candidate?.subStatus === 'Joined'}
            />
          </Tooltip>
        </div>
        <div className={classes.candidateMenuContainer}>
          <CandidateMenu
            candidate={candidate}
            changeJobApplicationStatus={changeJobApplicationStatus}
            refetch={refetch}
            jobStatus={jobStatus}
            hasAssessment={hasAssessment}
          />
        </div>
        <div className={classes.candidateInfo}>
          {hasPhoto ? (
            <img
              className={classes.coverImage}
              alt="Candidate"
              src={getResizedImageUrl({
                url: `avatar/${candidate.user.userProfile.avatar}`,
                width: 120,
              })}
            />
          ) : (
            <div className={classes.username}>
              {candidate.user?.userProfile?.firstName.charAt(0)}
              {candidate.user?.userProfile?.lastName.charAt(0)}
            </div>
          )}
          <h3>
            {candidate.user?.userProfile?.firstName}{' '}
            {candidate.user?.userProfile?.lastName}
          </h3>
          <div className={classNames(classes.status, classes[colorClass])}>
            {candidateCardStatus}
          </div>
          <div className={classes.hiringDetails}>
            <JobInfo application={candidate} width="100%" isCandidateCard />
          </div>
        </div>
        <footer className={classes.footer}>
          <Tooltip
            text={t(
              'dashboardComponents.Jobs.MyJobs.JobDetails.GridView.CandidatesCol.CandidateCard.elapsedTime'
            )}
            width={111}
            direction="top"
          >
            <div className={classes.timeAgo}>
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.58203 18C14.5526 18 18.582 13.9706 18.582 9C18.582 4.02944 14.5526 0 9.58203 0C4.61147 0 0.582031 4.02944 0.582031 9C0.582031 13.9706 4.61147 18 9.58203 18ZM10.582 3.5C10.582 2.94772 10.1343 2.5 9.58203 2.5C9.02975 2.5 8.58203 2.94772 8.58203 3.5V8.75C8.58203 9.44036 9.14168 10 9.83203 10H13.082C13.6343 10 14.082 9.55228 14.082 9C14.082 8.44772 13.6343 8 13.082 8H10.582V3.5Z"
                  fill={dateIconColor}
                />
              </svg>
              <span
                className={classNames(classes.createdAt, {
                  [classes.new]:
                    getTimeAgo(candidate.statusChangedAt) === 'New',
                })}
              >
                {candidate?.status === 'Invited'
                  ? getTimeAgo(candidate?.createdAt)
                  : getTimeAgo(candidate.statusChangedAt)}
              </span>
            </div>
          </Tooltip>
          <ResumeIcon resumeType={resumeType} />
        </footer>
      </div>
    </div>
  );
}
